import toast from "react-hot-toast";
import axios from "axios";
import { callGetApi, callPostApi } from "../../../api/ApiCaller";
import { Get_Reward_Point_And_Wallet_Balance, Post_Complete_Post_PaidOrder } from "../../../api/ApiConst";
import { NO_ERROR } from "../../../api/constants";
import { SET_ORDER_TYPE, SET_RESTAURANT_ID } from "../../../reducer/actions/utilityActions";
import { DINE_IN, HOME_DELIVERY, ORDER_PLACED, TAKE_AWAY } from "../../../translations/en/en";
import * as Storage from "../../../utils/GetSetStorage";
import * as AirBridgeUtilities from "../../airbridge/AirBridgeUtilities";
import { InformationAlert } from "../../common";
import { Fragment } from "react";

export const getItemTotalAmount = (items) => {
    const total = items.reduce((accumulator, currentValue) => accumulator + currentValue.finalPrice * currentValue.quantity, 0);
    return total;
};

export const getDealCartTotal = () => {
    let dealCart = Storage.getDealCart();
    // console.log("dealCart",dealCart)
    if (!dealCart || dealCart.length === 0) {
        return 0;
    }

    return Storage.getDealCart().reduce((accumulator, currentItem) => accumulator + currentItem.itemPrice * currentItem.stealDealItemQty, 0);
};

export const checkAvailableNearByOrderType = (restaurantList, dispatchUtility) => {
    // TO check if nearby Order type is available
    const _restaurantList = restaurantList;
    let isDineInAvailable = false;
    let isDeliveryAvailable = false;
    let isTakeAwayAvailable = false;
    let selectedRestaurantObj = "";
    for (let i = 0; i < _restaurantList.length; i++) {
        const { distanceInMeter, availableInDineIn, availableInHomeDelivery, availableInTakeAway } = _restaurantList[i];

        if (distanceInMeter <= 200 && availableInDineIn) {
            isDineInAvailable = true;
            isTakeAwayAvailable = false;
            isDeliveryAvailable = false;
            selectedRestaurantObj = _restaurantList[i];
            break;
        } else if (availableInTakeAway) {
            isDineInAvailable = false;
            isDeliveryAvailable = false;
            isTakeAwayAvailable = true;
            selectedRestaurantObj = _restaurantList[i];
            break;
        } else if (availableInHomeDelivery) {
            isDineInAvailable = false;
            isTakeAwayAvailable = false;
            isDeliveryAvailable = true;
            selectedRestaurantObj = _restaurantList[i];
            break;
        }
    }
    const selectedRestaurantDetails = {
        restaurantId: selectedRestaurantObj.restaurantUuid,
        restaurantName: selectedRestaurantObj.title,
        restaurantRecordId: selectedRestaurantObj.recordId,
    };
    dispatchUtility({
        type: SET_RESTAURANT_ID,
        payload: selectedRestaurantDetails,
    });
    if (isDineInAvailable) {
        dispatchUtility({ type: SET_ORDER_TYPE, payload: DINE_IN });
    } else if (isDeliveryAvailable) {
        dispatchUtility({ type: SET_ORDER_TYPE, payload: HOME_DELIVERY });
    } else if (isTakeAwayAvailable) {
        dispatchUtility({ type: SET_ORDER_TYPE, payload: TAKE_AWAY });
    } else {
        // Default
        dispatchUtility({ type: SET_ORDER_TYPE, payload: HOME_DELIVERY });
    }
};

export const handleGoBack = (location, navigate) => {
    if (location.key === "default") {
        navigate("/");
    } else {
        navigate(-1);
    }
};

export const getRewardPointAndWalletBalance = () => {
    return new Promise((resolve, reject) => {
        let rUrl = Get_Reward_Point_And_Wallet_Balance;
        callGetApi(
            rUrl,
            {},
            (response) => {
                if (response.errorCode === NO_ERROR) {
                    resolve(response.responsePacket);
                } else {
                    InformationAlert({ message: response.message });
                    reject(response.message);
                }
            },
            (error) => {
                const serverMessage = error.response?.data?.message;
                const errorMessage = error.message;
                reject(serverMessage ? serverMessage : errorMessage);
            }
        );
    });
};

export const completePostPaidOrder = (tempRequestObj) => {
    const { orderRefId, orderTotal, orderDetail, handleNavigation, txnId } = tempRequestObj;
    const rObj = {
        paidByWallet: orderTotal,
    };

    let rUrl = Post_Complete_Post_PaidOrder.replaceAll("{orderRefId}", orderRefId);
    callPostApi(rUrl, rObj, {}, (placeOrderRes) => {
        if (placeOrderRes.errorCode === NO_ERROR) {
            // handleSetLastPaymentMode()
            InformationAlert({ title: ORDER_PLACED, message: placeOrderRes.message });
            handleNavigation();
            //   clevertapEvents.pushChargedEvent(orderDetails, lastPaymentModeObj.lastPaymentMode)
            AirBridgeUtilities.orderCompletedEvent(orderDetail, txnId);
        } else {
            InformationAlert({ message: placeOrderRes.message });
        }
    });
};

export const Upcoming_Restaurants = [
    // "Amritsar",
    // "Phagwara",
    // "Amayra Emporio, Kharar Highway",
    "Agra",
    "Noida",
    //   "Sri Ganganagar",
    //   "Sangrur",
    "Meerut",
    "Mullanpur (Ludhiana)",
    // "Bathinda",
    "Nawanshahr",
    "Mussoorie",
    "Haldwani",
    "Ropar",
    "Behram, Phagwara-Nawashar Highway",
    "London Street, Morinda",
    "Faridabad",
    "Anantara Karvan, Ropar-Manali Highway",
    "Downtown, Phase 8, Mohali",
    "Bharatmala Highway, Patra",
    "Patiala",
    // "Chd-Ludhiana Road",
    // "Kharar-Ropar Road",
];

export const getDefaultLocation = () => {
    const userAddress = Storage.getParsedValueFromStorage("selectedUserAddress");
    if (userAddress) {
        return userAddress;
    } else {
        const address = {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            addressType: "",
            latitude: 0,
            longitude: 0,
            recordId: null,
        };

        address.addressLine1 = "NH 21, Chandigarh Kharar Road, Sec 118, SAS Nagar, Mohali";
        address.latitude = 30.7374;
        address.longitude = 76.6762;

        return address;
    }
};

export const getLatLng = () => {
    return new Promise((resolve, reject) => {
        const getCurrentLocation = async () => {
            const isGeoLocationAvailable = await navigator.permissions.query({
                name: "geolocation",
            });

            if (!navigator.geolocation) {
                toast("Geolocation is not supported by your browser");
                // reject(new Error("Geolocation is not supported by your browser"))
                return;
            }

            if (!isGeoLocationAvailable) {
                toast("location permission is disabled");
                // reject(new Error("location permission is disabled"))
                return;
            }

            const address = {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                addressType: "",
                latitude: 0,
                longitude: 0,
                recordId: null,
            };

            const locationPermissionOptions = {
                timeout: 5000,
            };

            const onSuccess = (position) => {
                address.latitude = position.coords.latitude;
                address.longitude = position.coords.longitude;
                axios
                    .post(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyBwD9qASVx4Jjqei_IZzUPvp3Xdh_5ATOo`)
                    .then((response) => {
                        let formattedAddress = response.data.results[0].formatted_address;
                        let removedWord = formattedAddress.split(",")[0];
                        const finalAddress = formattedAddress.replace(removedWord + ",", "");
                        address.addressLine1 = finalAddress;
                        resolve(address);
                    })
                    .catch((error) => {
                        resolve(getDefaultLocation());
                    });
            };

            const onError = (error) => {
                resolve(getDefaultLocation());
            };

            navigator.geolocation.getCurrentPosition(onSuccess, onError, locationPermissionOptions);
        };

        getCurrentLocation();
    });
};

export const getFormattedDescription = (description = "", showIndex = false) => {
    if (description) {
        let message = "";
        if (showIndex) {
            message = description.split(/\r\n\r\n|\r\n|\n/);
        } else {
            message = description.split("\r\n\r\n");
        }
        const newMessage = (
            <ol className="terms-list">
                {message.map((term, i) => {
                    const index = i + 1;
                    let content = "";
                    if (showIndex) {
                        content = term.replace(/^\d+\.\s*/, "");
                    } else {
                        content = term.replaceAll("\r\n", "<br/>");
                    }

                    return (
                        <Fragment key={i}>
                            <li className="d-flex my-2">
                                {showIndex && <div className="term-number">{index}.</div>}
                                <div className="term-content" dangerouslySetInnerHTML={{ __html: content }}></div>
                            </li>
                            {/* {!showIndex && <br />} */}
                        </Fragment>
                    );
                })}
            </ol>
        );
        return newMessage;
    }
    return description;
};
